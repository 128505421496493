:root{
  --gris:#F2F2F2;
  --azul:#303AF2;
  --naranja:#F29F05;
  --negro:#0D0D0D;
}

.Link_Button{
  text-decoration: none !important;
}

.foto-carrousel{
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #464646 ;
  height: 500px;
  width: 100%;
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  align-items: end;
}
.text-carrousel{
  position: absolute;
  background-color: var(--negro);
  opacity: 0.1;
  width: 100%;
  height: 6rem;
  bottom: 0;
}



.texto-portada-vision {
  
  margin-top: 2rem;
  text-align: center;
  text-justify: distribute;
 

}


.texto-portada-vision p {
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  font-weight: 600;

}
.texto-portada-vision ul {
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
  font-weight: 600;
}


.foto-carrousel h6{
  position: absolute;
  bottom: 1.5rem;
  font-family:  Helvetica;
  font-size: 2.5rem;
  font-weight: bold;

  text-align: justify;
}
#foto-carrousel-1{
 background-image: url("./assest/imagenes/cpf401.jpg");

}


#foto-carrousel-2{
  background-image: url("./assest/imagenes/foto2.WebP");
 }
#foto-carrousel-3{
  background-image: url("./assest/imagenes/foto3.WebP");
  
  }

  .text-carrousel{
    padding: 20px;
  }

  #foto_institucional{
    background-image: url("./assest/institucional.webp");
   
  }
.barra-horizontal{
  width:80%;
  height: 2px;
  background-color: #F2F2F2;
}


@media only screen and (max-width: 600px) {
  .foto-carrousel{
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646 ;
    height: 300px;
    width: 100%;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .foto-carrousel h6{
    display:none;
      
  }
  .text-carrousel{
   display: none;
  }

  

}
.Banner-Novedades{
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 992px) {

  .foto-carrousel h6{
    
 
    font-size: 1.5rem; 
  }

}

